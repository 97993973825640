import React from 'react';
import {
  Footer,
  FooterText,
  Header,
  HeaderContent,
  HeaderSubtitle,
  HeaderText,
  HeaderTitle,
  Page,
  Section,
  SectionText,
  SectionTitle,
} from './HomepageStyle';

const Homepage = () => {
  return (
    <Page>
      <Header>
        <HeaderContent>
          <HeaderTitle>
            SDR<span>.Bot</span>
          </HeaderTitle>
          <HeaderSubtitle>Inbound lead management evolved</HeaderSubtitle>
          <HeaderText>Coming soon...</HeaderText>
        </HeaderContent>
      </Header>
      <Section>
        <SectionTitle>
          Get more quality leads with intelligent and instant user qualification
        </SectionTitle>
        <SectionText>
          How long does it take your organization to qualify a user for a sales call? If you
          answered “more than 1 minute” you are losing out on a serious chunk of qualified
          prospects.
        </SectionText>
        <SectionText>
          SDR bot offers sales organizations the most efficient way to give users an instant
          qualification experience.
        </SectionText>
        <SectionText>
          <b>Why SDR.BOT?</b>
        </SectionText>
        <SectionText>
          <ul>
            <li>
              <b>Instant Engagement: </b>
              <span>
                Engage prospects the moment they show interest, with SDR.BOT’s instant response
                capabilities enhancing customer experience and increasing conversion likelihood.
              </span>
            </li>
            <li>
              <b>Consistent Quality: </b>
              <span>
                Human SDR teams are not always accurate with their qualification, either passing too
                many or too little leads.
              </span>
            </li>
            <li>
              <b>Multilingual Support: </b>
              <span>
                Break language barriers by employing SDR.BOT’s multilingual capabilities, reaching a
                global audience without the need for international staff.
              </span>
            </li>
            <li>
              <b>Customizable Interaction: </b>
              <span>
                Tailor SDR.BOT’s interactions to match your brand’s voice and sales approach,
                providing a personalized experience to every lead.
              </span>
            </li>
            <li>
              <b>Seamless CRM Integration: </b>
              <span>
                SDR.BOT integrates with existing CRM systems, ensuring a smooth workflow and keeping
                all sales-related data in sync.
              </span>
            </li>
            <li>
              <b>Scalability: </b>
              <span>
                SDR.BOT can handle an unlimited volume of leads simultaneously, giving your business
                the ability to scale operations without additional costs.
              </span>
            </li>
          </ul>
        </SectionText>
      </Section>
      <Footer>
        <FooterText>&copy; 2023 SDR.BOT | Inbound lead management</FooterText>
      </Footer>
    </Page>
  );
};

export default Homepage;
